(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  window.IS_MOBILE = !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i);
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW.on('resize', function() {
    window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
    window.WINDOW_HEIGHT = $WINDOW.height();
    window.HEADER_HEIGHT = $HEADER.height();
  });
  window.HEADER_INFO_MENU = $('.header-info-menu-container ul.header-info-menu');
  window.HEADER_SERVICE_MENU = $('.header-menu-container ul.header-menu');

  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  }
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  }
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH );
  }
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  }
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  }
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  }


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  /*$('input[type="tel"]')
    .inputmask('+7 (999) 999-99-99', {
      placeholder: "+7 (___) ___-__-__"
    })
    .on('paste', function(e) {
      console.log(arguments);
      alert('test');
    })*/;


  $WINDOW
    .on('resize', function() {
      if( IS_LANDSCAPE_TABLET_WIDTH() ) {
        $('.gallery-rotator-block-container.gallery-items-width-4 .gallery-rotator-image img').attr('src', function() {
          return $(this).data('pre2');
        });
      }
      else {
        $('.gallery-rotator-block-container.gallery-items-width-4 .gallery-rotator-image img').attr('src', function() {
          return $(this).data('pre4');
        });
      }
    })
    .trigger('resize');


  $DOCUMENT
    // Scroll to
    // ---------
    .on('click.js-scroll-to', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $link = $(this),
          $elemToScroll = $($link.attr('href').substr($link.attr('href').lastIndexOf('#'))),
          speed = $link.data('scrollSpeed') || 150,
          offset = $link.data('scrollOffset') || $elemToScroll.data('scrollOffset') || 0;

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Social window open
    // ------------------
    .on('click.social-window-open', '.footer-social a', function(e) {
      e.preventDefault();

      var socialWin;

      if( socialWin = window.open($(this).prop('href'), '_blank', 'chrome=1,centerscreen=1,dialog=1,modal=0,close=1,directories=0,titlebar=1,toolbar=1,location=1,status=1,menubar=0,scrollbars=1,resizable=1,top=120,left=30,width=1040,height=600')) {
        socialWin.focus();
      }
    })

    .on('click.header-callback-link', '.header-callback-link a', function() {
      CALLBACKS.phoneCallbackHeaderLink();
    })

    // Price groupped quick links
    // --------------------------
    .on('click.price-groupped-quick-links', '.price-groupped-table-container .quick-link-list a', function(e) {
      e.preventDefault();

      var $link = $(this);

      if( !$link.parent().hasClass('active') ) {
        $link
          .parent().addClass('active')
          .siblings().removeClass('active')
          .first().parent().parent().addClass('selected')
          .parent().find('.price-table-container .price-table').addClass('selected')
          .find($link.attr('href').substr($link.attr('href').lastIndexOf('#')))
          .parent().addClass('active')
          .siblings().removeClass('active');
      }
    })

    // Portfolio info price item
    // -------------------------
    .on('click.portfolio-info-price-item', '.portfolio-info-icons .price-item > span', function(e) {
      e.preventDefault();

      var $container = $('#portfolio-info-price-notice');

      if( !$container.html() ) {
        $.mouseLoader(true);

        $.ajax({
          type: 'POST',
          url: '/portfolio_info_price_notice/',
          success: function(response) {
            $.mouseLoader(false);

            $container.html(response);
            $.fancybox.open($container);
          },
          error: function() {
            $.mouseLoader(false);
          },
          dataType: 'html'
        });
      }
      else
        $.fancybox.open($container);
    })

    // Show response hidden text
    // -------------------------
    .on('click.responseTextMore', '.response-item > div > div.more', function(e) {
      e.preventDefault();

      var $self = $(this);

      if( $self.hasClass('is-opened') ) {
        $self.removeClass('is-opened');

        if( $WINDOW.scrollTop() > $self.offset().top )
          $WINDOW.scrollTo($self, {duration: 0, offset: -30});
      }
      else
        $self.addClass('is-opened');
    })

    // Faq list answers visible toggle
    // -------------------------------
    .on('click.faqListAnswer', '.faq-list > div > div:first-child', function(e) {
      e.preventDefault();

      var $self = $(this).parent();

      if (!$self.hasClass('active'))
        $self.addClass('active').children('div + div').slideDown(200);
      else
        $self.removeClass('active').children('div + div').slideUp(200);
    });

  if( IS_DESKTOP_WIDTH() ) {
    $DOCUMENT
      .ready(function() {
        // Fix service submenu popup position
        // ----------------------------------
        var headerServiceMenuWidth = HEADER_SERVICE_MENU.outerWidth();
        HEADER_SERVICE_MENU.children().each(function() {
          var $item = $(this),
              $submenu = $(this).children('ul');

          if( $submenu.length ) {
            $submenu.css('max-width', headerServiceMenuWidth);

            if( $submenu.outerWidth() + $item.position().left > headerServiceMenuWidth )
              $submenu.css('left', 0 - ($submenu.outerWidth() + $item.position().left - headerServiceMenuWidth));
          }
        });
      });
  }
  else {
    // Header menu button
    // ------------------
    $MENU_CHECKBOX
      .on('change', function() {
        if( this.checked ) {
          $BODY.addClass('menu-is-opened');
        } else {
          $BODY.removeClass('menu-is-opened');
        }
      })
      .prop('checked', false)
      .prop('disabled', false)
      .trigger('change');

    $DOCUMENT
      // Move service items to header info menu
      // --------------------------------------
      .ready(function() {
        HEADER_INFO_MENU.children().first().addClass('is-hovered').children('ul').css('display', 'block');
        HEADER_SERVICE_MENU.children().prependTo(HEADER_INFO_MENU);
      })

      // Close header menu
      // -----------------
      .on('click.close-header-menu', function(e) {
        if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-info-menu-container ul').length) ) {
          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          e.stopPropagation();
        }
      })

      // Header menu items
      .on('click.header-menu', '.header-info-menu div > a, .header-info-menu div > span, .header-info-menu .dropmarker', function(e) {
        e.preventDefault();

        var $self = $(this),
            $parent = $self.closest('li'),
            isWithDropdown = $parent.hasClass('with-dropdown'),
            isOnHover = $parent.hasClass('is-hovered');

        $parent.siblings('.with-dropdown').removeClass('is-hovered').children('ul').slideUp(300);

        if( isWithDropdown ) {
          if( isOnHover ) {
            if( $self.prop("tagName").toUpperCase() === 'A' )
              location.href = $self.prop('href');
            else
              $parent.removeClass('is-hovered').children('ul').slideUp(300);
          } else {
            $parent.addClass('is-hovered').children('ul').slideDown(300);
          }
        } else {
          if( $self.prop("tagName").toUpperCase() === 'A' )
            location.href = $self.prop('href');
        }
      });
  }


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    infobar: false,
    smallBtn: false,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      autoFocus: false,
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch(current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    }
  });


  // Slick sliders
  // -------------
  var slickSettings = {
    autoplay: true,
    autoplaySpeed: 8000,
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    pauseOnDotsHover: true,
    customPaging: function() { return $('<div />'); },
    draggable: false
  };

    // Main rotator
    $('.main-rotator-wrapper').slick($.extend({}, slickSettings, {
      centerMode: true,
      centerPadding: '0px',
      arrows: true,
      dots: false,
      slide: '.main-rotator-slide',
      appendArrows: '.main-rotator-wrapper > .main-rotator-arrows',
      draggable: true
    }));

    // Main gallery rotator
    $('.gallery-rotator-block-container .list-view-item').each(function() {
      var $rotator = $(this).find('.gallery-rotator'),
          $pager = !IS_LARGE_PORTRAIT_MOBILE_WIDTH() ? $(this).find('.gallery-rotator-pager > div') : null;

      $rotator.slick($.extend({}, slickSettings, {
        autoplay: true,
        asNavFor: $pager,
        arrows: false,
        slide: '.gallery-rotator-image'
      }));

      if( !IS_LARGE_PORTRAIT_MOBILE_WIDTH() )
        $pager.slick($.extend({}, slickSettings, {
          autoplay: true,
          asNavFor: $rotator,
          centerMode: false,
          arrows: false,
          focusOnSelect: true,
          slide: '.gallery-rotator-pager-image',
          slidesToShow: 7,
          swipe: false
        }));
    });


  // Portfolio filter
  // ----------------
  if( $('.portfolio-page-block-container').length ) {
    var $portfolioGrid = $('.portfolio-page-block-container .portfolio-items').isotope({
          itemSelector: '.list-view-item',
          layoutMode: 'fitRows',
          percentPosition: true,
          transitionDuration: '0.5s',
          containerStyle: null
        }),
        $links = $('.portfolio-page-block-container .quick-link-list a');

    $links.on('click.portfolio-filter', function(e) {
      e.preventDefault();

      location.hash = encodeURIComponent( $(this).data('filterValue') );
    });

    $WINDOW.on('hashchange', function(e) {
      var filterValue = decodeURIComponent( location.hash.slice(1) );

      if( filterValue.length ) {
        $links.removeClass('active').filter('[data-filter-value="' + filterValue + '"]').addClass('active');

        if( filterValue === 'all' ) {
          $portfolioGrid.isotope({ filter: '*'});
        } else {
          $portfolioGrid.isotope({ filter: '[data-filter-section="' + filterValue  + '"]'});
        }
      }
    });
  }
})();